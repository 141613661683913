import { colors } from '_/constants/theme';
import { useDateContext } from '_/hooks/DateContext';
import { useEventContext } from '_/hooks/EventContext';
import { useSlotContext } from '_/hooks/SlotContext';
import { useSpaceContext } from '_/hooks/SpaceContext';
import NoAvailableSlots from '_/screens/Workstation/StatusTab/AvailableList/NoAvailableSlots';
import { SlotsModel } from '_/services/models/slots.model';
import { startOfDay, parseISO, endOfDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ActivityIndicator, Platform, RefreshControl, SectionList } from 'react-native';

import MeetingRoom from '../MeetingRoom';
import WeekCalendar from '../WeekCalendar';
import WebWeekCalendar from '../WeekCalendar/WebWeekCalendar';

export default function RoomList() {
  const [rooms, setRooms] = useState<SlotsModel[]>([]);
  const [loading, setLoading] = useState(true);
  const [calendarVisibleId, setCalendarVisibleId] = useState('');

  const { selectedDay, timePickerVisible, selectedEventDate, checkIfThereIsConflict } =
    useDateContext();
  const { getSpaceEvents, setMeetingReservations, meetingEvents } = useEventContext();
  const { spaceId } = useSpaceContext();
  const { getAvailableSlotsbySpaceId } = useSlotContext();

  async function getRooms() {
    const data = await getAvailableSlotsbySpaceId(
      spaceId,
      timePickerVisible ? selectedEventDate.start : startOfDay(parseISO(selectedDay)),
      timePickerVisible ? selectedEventDate.end : endOfDay(parseISO(selectedDay))
    );
    setRooms(data);
    setLoading(false);
  }

  const getReservations = useCallback(async () => {
    const data = await getSpaceEvents({
      spaceId,
      startAtTime: startOfDay(parseISO(selectedDay)),
      endAtTime: endOfDay(parseISO(selectedDay)),
    });
    const eventsForThisDate = data?.slots
      .map((s: SlotsModel) => {
        return s.events?.map((e) => e);
      })
      .flat();
    setMeetingReservations(eventsForThisDate);
  }, [getSpaceEvents, selectedDay, setMeetingReservations, spaceId]);

  useEffect(() => {
    getRooms();
    getReservations();
  }, [selectedDay]);

  useEffect(() => {
    checkIfThereIsConflict(selectedEventDate, meetingEvents);
  }, [checkIfThereIsConflict, meetingEvents, selectedEventDate]);

  const data = useMemo(() => {
    return rooms.map((room) => {
      return {
        title: room.name,
        data: [room],
      };
    });
  }, [rooms]);

  useEffect(() => {
    rooms.length === 1 && setCalendarVisibleId(rooms[0].id);
  }, [rooms]);

  const renderItem = useCallback(
    ({ item }: any) => {
      if (timePickerVisible) {
        return null;
      }
      if (Platform.OS === 'web') {
        return <WebWeekCalendar visible={calendarVisibleId === item.id} slot={item} />;
      } else {
        return <WeekCalendar visible={calendarVisibleId === item.id} slot={item} />;
      }
    },
    [calendarVisibleId, timePickerVisible]
  );

  const renderSectionHeader = useCallback(
    ({ section }: { section: { title: string; data: SlotsModel[] } }) => (
      <MeetingRoom
        slot={section.data[0]}
        amenities={section.data[0]?.amenities?.sort((a, b) => a.name.localeCompare(b.name))}
        calendarVisibleId={calendarVisibleId}
        setCalendarVisibleId={setCalendarVisibleId}
      />
    ),
    [calendarVisibleId]
  );

  return (
    <SectionList
      key="RoomList"
      initialNumToRender={5}
      maxToRenderPerBatch={5}
      keyExtractor={(item) => item.id}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
      ListEmptyComponent={
        loading ? (
          <ActivityIndicator color={colors.black} size={35} style={{ marginTop: 20 }} />
        ) : (
          <NoAvailableSlots maxCapacityReached={false} notAllowed />
        )
      }
      stickySectionHeadersEnabled
      sections={data}
      showsVerticalScrollIndicator={false}
      refreshControl={
        <RefreshControl
          refreshing={false}
          onRefresh={async () => {
            await getRooms();
          }}
        />
      }
    />
  );
}
